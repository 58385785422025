<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import store from '@/store'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    const navMenuItems = ref([])
    
    onMounted(async () => {
      await store.dispatch('menu/getNavMenuItems')
      navMenuItems.value = store.getters['menu/navMenuItems']
    })
    return {
      navMenuItems,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
